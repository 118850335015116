class wzCrossSellBanner {

    constructor() {
        this.wzModal = $('.wz-xsell-modal').length > 0 ? $('.wz-xsell-modal') : '';
        this.wzModalOverlay = $('.wz-overlay').length > 0 ? $('.wz-overlay') : '';
        this.termsApply = $('.wz-crossmarketing_terms_inner a.welcome_promoterms').length > 0 ? $('.wz-crossmarketing_terms_inner a.welcome_promoterms') : '';
        this.secondTermsApply = $('#second_promoterms');
        this.bannerRulls = {
            BZ: {
                FI: {
                    URL:"NONE",
                    URL_LVP:"https://ivyaffsolutions.com/C.ashx?btag=a_28334b_187c_&affid=347&siteid=28334&adid=187&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35768b_860c_&affid=347&siteid=35768&adid=860&c=",
                    TYPE: "wo",
                    GOTO: "cs"
                },
            },
            BJC: {
                FI: {
                    URL:"NONE",
                    URL_LVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30652b_187c_&affid=347&siteid=30652&adid=187&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30652b_860c_&affid=347&siteid=30652&adid=860&c=",
                    TYPE: "wo",
                    GOTO: "cs"
                }
            },
            LC: {
                FI: {
                    URL:"NONE",
                    URL_LVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30652b_187c_&affid=347&siteid=30652&adid=187&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35772b_860c_&affid=347&siteid=35772&adid=860&c=",
                    TYPE: "wo",
                    GOTO: "cs"
                }
            },
            VT: {
                FI: {
                    URL:"NONE",
                    URL_LVP:"https://ivyaffsolutions.com/C.ashx?btag=a_28604b_187c_&affid=347&siteid=28604&adid=187&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35776b_860c_&affid=347&siteid=35776&adid=860&c=",
                    TYPE: "wo",
                    GOTO: "cs"
                }
            },
            CL: {
                FI: {
                    URL:"NONE",
                    URL_LVP:"https://ivyaffsolutions.com/C.ashx?btag=a_28605b_187c_&affid=347&siteid=28605&adid=187&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35769b_860c_&affid=347&siteid=35769&adid=860&c=",
                    TYPE: "wo",
                    GOTO: "cs"
                }
            },
            HK: {
                FI: {
                    URL:"NONE",
                    URL_LVP:"https://ivyaffsolutions.com/C.ashx?btag=a_28606b_187c_&affid=347&siteid=28606&adid=187&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35770b_860c_&affid=347&siteid=35770&adid=860&c=",
                    TYPE: "wo",
                    GOTO: "cs"
                }
            },
            PT: {
                FI: {
                    URL:"NONE",
                    URL_LVP:"https://ivyaffsolutions.com/C.ashx?btag=a_27610b_187c_&affid=347&siteid=27610&adid=187&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35773b_860c_&affid=347&siteid=35773&adid=860&c=",
                    TYPE: "wo",
                    GOTO: "cs"
                }
            },
            SP: {
                FI: {
                    URL:"NONE",
                    URL_LVP:"https://ivyaffsolutions.com/C.ashx?btag=a_27609b_187c_&affid=347&siteid=27609&adid=187&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35775b_860c_&affid=347&siteid=35775&adid=860&c=",
                    TYPE: "wo",
                    GOTO: "cs"
                }
            },
            RC: {
                FI: {
                    URL:"NONE",
                    URL_LVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35687b_187c_&affid=347&siteid=35687&adid=187&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35774b_860c_&affid=347&siteid=35774&adid=860&c=",
                    TYPE: "wo",
                    GOTO: "cs"
                }
            },
            IC: {
                FI: {
                    URL:"NONE",
                    URL_LVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35686b_187c_&affid=347&siteid=35686&adid=187&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35771b_860c_&affid=347&siteid=35771&adid=860&c=",
                    TYPE: "wo",
                    GOTO: "cs"
                }
            }
        };
        this.isLegacy = ['SR','SL', 'DV'];
    }

    initModal() {
        console.log('wz-show modal');
        wzCrossSell.wzModal.toggleClass('wz-show');
        wzCrossSell.wzModalOverlay.toggleClass('wz-show');
    }

    closeModal() {
        console.log('close modal');
        wzCrossSell.wzModal.removeClass('wz-show');
        wzCrossSell.wzModalOverlay.removeClass('wz-show');

        $('.second-terms')?.css('display', 'none');
        $('.welcome-terms')?.removeAttr('style');
    }

    setLegacy() {
        return $.inArray(basicInfo.brandshort, this.isLegacy) !== -1 ? true : false;
    }


    initUrl() {
        console.log(basicInfo.brand, ' Init');


        let bannerElement = document.querySelector('.wz-crossmarketing_banner');
        let playerType = bannerElement.getAttribute('data-player');

        let bannerUrlLink = '';
        if(wzCrossSell.bannerRulls[basicInfo.brandshort][basicInfo.geo]) {
            if(playerType == 'hvp') {
                bannerUrlLink = wzCrossSell.bannerRulls[basicInfo.brandshort][basicInfo.geo].URL_HVP;
            } else {
                bannerUrlLink = wzCrossSell.bannerRulls[basicInfo.brandshort][basicInfo.geo].URL_LVP;
            }
        } else if (basicInfo.isLatam && wzCrossSell.bannerRulls[basicInfo.brandshort]['LATAM']) {
            bannerUrlLink = wzCrossSell.bannerRulls[basicInfo.brandshort]['LATAM'].URL;
        }
        if(!wzCrossSell.setLegacy()) {
            wzEventHandler.GAEvent('Click', basicInfo.brand, 'CrossMarketingBanner');
        }
        window.location.href = bannerUrlLink;
    }

    init() {
        if(this.setLegacy()) {
            $('body').on('click', '[data-action="wz.modal.open"]', wzCrossSell.initModal);
            $('body').on('click', '[data-action="wz.modal.close"]',wzCrossSell.closeModal);
            $('body').on('click', '[data-action="goto.crossmarketing"]', wzCrossSell.initUrl);
        }else {
            wzEventHandler.addEvent('wz.modal.open', this.initModal);
            wzEventHandler.addEvent('wz.modal.close', this.closeModal);
            wzEventHandler.addEvent('goto.crossmarketing', this.initUrl);
        }

        if (this.termsApply.length > 0 ) {
            this.termsApply.attr('href', 'javascript:void(0);');
            $('a.wzCMurl').attr('href', 'javascript:void(0);');
            this.termsApply.attr('data-action', 'wz.modal.open');
        }

        this.secondTermsApply.on('click', function () {
            $('.second-terms')?.removeAttr('style');
            $('.welcome-terms')?.css('display', 'none');
        });
    };
}


$(function() {
    var wzCrossSell = new wzCrossSellBanner();
    window.wzCrossSell = wzCrossSell;
    wzCrossSell.init();
});
